<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import permissions from '@/common/permissions'
import { routes } from '@/router'

export default {
  mounted() {
    if (this.getCurrentUserHasPermission(permissions.appAggregator)) {
      this.actionFetchDistributorList()
    } else {
      this.$router.push({ name: routes.dashboard })
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission'])
  },
  methods: {
    ...mapActions('distributor', ['actionFetchDistributorList'])
  }
}
</script>
