<template>
  <v-container>
    <v-row
        v-if="getStockCategoriesFetching || getStockUnitsFetching || exportingInProgress"
        class="fill-height"
        align-content="center"
        justify="center"
    >
      <v-col class="subtitle-1 text-center" cols="12">
        {{ (getStockCategoriesFetching || getStockUnitsFetching) ? 'Загрузка...' : 'Обработка...' }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear color="indigo" indeterminate rounded height="6"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-else justify="space-between" class="pt-6">
      <v-col cols="5">
        <div class="content-left">
          <div class="title">Выберите категории с товарами для экспорта</div>
          <div class="d-flex flex-row justify-center align-center">
            <v-text-field
                outlined
                dense
                class="mt-3 mr-3"
                label="Поиск по артикулу и наименованию товара"
                v-model="searchPhrase"
                @keyup.enter="refreshResults"
                :loading="isResultFetching"
                :disabled="isResultFetching"
            ></v-text-field>
            <v-btn
                color="primary"
                class="mt-n4"
                :loading="isResultFetching"
                :disabled="isResultFetching"
                @click="refreshResults"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
          <v-treeview
              open-all
              :items="treeItems"
              selection-type="independent"
              class="py-3"
              multiple-active
              hoverable
              open-on-click
              dense
          >
            <template #prepend="{ item }">
              <v-checkbox
                  v-if="item.products_unexported_count > 0"
                  @click.stop="treeCheckboxHandler(item)"
                  :input-value="selectedCategoriesIdList.includes(item.id)"
                  class="ma-0 pa-0"
                  hide-details
                  dense
              ></v-checkbox>
            </template>
            <template #label="{ item }">
              <span :class="!item.enabled && 'grey--text darken-3'">
                {{ item.name }}
              </span>
              <span class="font-weight-thin">
                {{ item.products_unexported_count ? `(${item.products_unexported_count})` : '' }}
              </span>
            </template>
          </v-treeview>
        </div>
      </v-col>
      <v-col cols="7">
        <div v-if="selectedCategories.length">
          <div class="title">Отметьте товары для экспорта</div>
          <div class="d-flex flex-grow-1 content-right pt-3">
            <v-expansion-panels
                :multiple="true"
                :focusable="true"
                class="align-start align-self-start"
                v-if="Object.keys($store.state.stock_products.stock_products).length"
                v-model="panels"
            >
              <CategoryPanel
                  v-for="item in selectedCategories"
                  :key="item.id"
                  :category="item"
                  :selectedProductsIdList="selectedProductsIdList"
                  :loading="categoryProductsFetchStatus[item.id] === fetchingStatus.inprogress"
                  @product-change="({ newValue, oldValue }) => selectProducts(newValue, oldValue)"
              ></CategoryPanel>
            </v-expansion-panels>
            <div v-else class="flex-grow-1 text-center py-12">
              <div class="title font-weight-light">Соответствующие выборке товары отсутствуют</div>
            </div>
          </div>
        </div>
        <div v-else class="flex-grow-1 text-center py-12">
          <div class="title font-weight-light">Нет выбранных категорий</div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-sticky-actions>
          <div class="py-4">
            <div class="d-flex justify-end flex-column flex-md-row">
              <v-btn
                  @click="resetChanges"
                  :disabled="!hasSelectedCategories"
                  class="mr-md-2"
                  color="error"
                  small
                  depressed
              >
                Сбросить
              </v-btn>
              <v-btn
                  @click="saveChanges"
                  :disabled="!hasSelectedProducts"
                  class="mt-1 mt-md-0"
                  color="primary"
                  small
                  depressed
              >
                Экспортировать
              </v-btn>
            </div>
          </div>
        </v-sticky-actions>
      </v-col>
    </v-row>

    <v-dialog v-model="reply_dialog.show" scrollable max-width="1100">
      <v-card>
        <v-card-title class="text-center">Экспортирование завершено</v-card-title>
        <v-card-text>
          <div>{{ reply_dialog.text }}</div>
          <div v-if="reply_dialog.exported_goods.length">
            <div class="mt-8 mb-5 ml-auto text-h6">Экспортированные товары</div>
            <v-data-table
                dense
                :items="reply_dialog.exported_goods"
                :headers="reply_dialog.exported_goods_headers"
                :items-per-page="10"
            >
              <template #item.name="{item: {id, name}}">
                <router-link
                    :to="{name: catalogProduct, params: {catalogType: 'structure', productId: id}}"
                    target="_blank"
                >{{ name }}</router-link>
              </template>
            </v-data-table>
          </div>
          <div v-if="reply_dialog.errors.length > 0">
            <div class="mt-8 mb-5 ml-auto text-h6">Ошибки</div>
            <v-data-table
                :items="reply_dialog.errors"
                :headers="reply_dialog.headers"
                :items-per-page="5"
                group-by="is_requested"
                group-desc
            >
              <template #group.header="group">
                <th colspan="2">
                  <v-icon @click="group.toggle">
                    {{ group.isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>

                  <span v-if="group.items[0].is_requested">В выбранных вами</span>
                  <span v-else>В попутно экспортированных</span>
                </th>
              </template>
              <template #item.target_id="{item}">
                <router-link
                    v-if="item.target === 'product'"
                    :to="`/catalog/structure/product/${item.target_id}`"
                    target="_blank"
                >Товар: {{ item.target_name }}
                </router-link>
                <router-link
                    v-else-if="item.target === 'category'"
                    :to="`/catalog/structure/${item.target_id}`"
                    target="_blank"
                >Категория #{{ item.target_id }}
                </router-link>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined class="grey--text ml-auto" @click="reply_dialog.show = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapActions} from 'vuex'
import stockProductsApi from '@/services/stockProductsApi'
import CategoryPanel from './CategoryPanel'
import VStickyActions from "@/components/globalVStickyActions";
import store from "@/store";
import {catalogProduct} from "@/router/routes";


const fetchingStatus = {
  inprogress: 'inprogress',
  success: 'success'
}

export default {
  components: {VStickyActions, CategoryPanel},
  data: () => ({
    catalogProduct,
    selectedCategoriesIdList: [],
    selectedProductsIdList: [],
    categoryProductsFetchStatus: {},
    exportingInProgress: false,
    searchPhrase: '',
    replyMessage: null,
    panels: [],
    reply_dialog: {
      headers: [
        {
          text: 'Объект',
          value: 'target_id',
          width: '50%'
        },
        {
          text: 'Описание',
          value: 'text',
        }
      ],
      exported_goods_headers: [
        {
          text: 'Товар',
          value: 'name'
        }
      ],
      show: false,
      text: null,
      errors: [],
      exported_goods: [],
    }
  }),
  created() {
    this.fetchingStatus = fetchingStatus
    this.actionClearStockProducts()
    this.actionFetchStockCategories()
    this.actionFetchStockUnits()
  },
  watch: {
    selectedCategoriesIdList() {
      this.refreshResults()
    }
  },
  computed: {
    ...mapGetters('stock_categories', [
      'getStockCategoriesFetching',
      'getStockCategoriesByIdList',
      'getFilteredStockRootCategories'
    ]),
    ...mapGetters('stock_products', ['getStockProductsByParams']),
    ...mapGetters('stock_units', ['getStockUnitsFetching']),
    treeItems: function () {
      return this.getFilteredStockRootCategories(item => {
        return item.products_unexported_count > 0
      })
    },
    selectedCategories: function () {
      return this.getStockCategoriesByIdList(this.selectedCategoriesIdList)
    },
    hasSelectedCategories: function () {
      return this.selectedCategoriesIdList.length
    },
    hasSelectedProducts: function () {
      return this.selectedProductsIdList.length
    },
    isResultFetching: function () {
      return Object.values(this.categoryProductsFetchStatus).filter(e => e !== "success").length > 0
    }
  },
  methods: {
    ...mapActions('stock_categories', ['actionFetchStockCategories']),
    ...mapActions('stock_products', [
      'actionFetchStockProducts',
      'actionClearStockProducts'
    ]),
    ...mapActions('stock_units', ['actionFetchStockUnits']),
    refreshResults() {
      this.panels = []

      for (let i = 0; i < this.selectedCategoriesIdList.length; i++)
        this.panels.push(i)

      if (!this.selectedCategoriesIdList.length && this.searchPhrase.trim().length) {
        this.actionFetchStockProducts({
          is_exported_to_1c: false,
          is_exportable_to_1c: false,
          name_or_article_filter: this.searchPhrase
        }).then(({dataList}) => {
          for (let good of dataList) {
            if (this.selectedCategoriesIdList.indexOf(good.structure) === -1) {
              this.selectedCategoriesIdList.push(good.structure)
            }
          }
        })
      } else {
        this.selectedCategoriesIdList.forEach(id => {
          this.categoryProductsFetchStatus = {
            ...this.categoryProductsFetchStatus,
            [id]: fetchingStatus.inprogress
          }
          this.actionClearStockProducts()
          this.actionFetchStockProducts({
            structure: id,
            is_exported_to_1c: false,
            is_exportable_to_1c: false,
            name_or_article_filter: this.searchPhrase
          }).then(() => {
            this.categoryProductsFetchStatus = {
              ...this.categoryProductsFetchStatus,
              [id]: fetchingStatus.success
            }
          })
        })
      }
    },
    treeCheckboxHandler: function (item) {
      let remove = this.selectedCategoriesIdList.includes(item.id)
      this.selectedCategoriesIdList = remove
          ? _.difference(this.selectedCategoriesIdList, [item.id])
          : [...this.selectedCategoriesIdList, item.id]
      if (remove) {
        this.selectedProductsIdList = _.difference(
            this.selectedProductsIdList,
            this.getStockProductsByParams({
              structure: item.id
            }).map(item => item.id)
        )
      }
    },
    selectProducts: function (newValue, oldValue) {
      this.selectedProductsIdList = [
        ..._.difference(this.selectedProductsIdList, oldValue),
        ...newValue
      ]
    },
    resetChanges: function () {
      this.selectedProductsIdList = []
      this.selectedCategoriesIdList = []
    },
    saveChanges: function () {
      this.exportingInProgress = true
      stockProductsApi
          .exportStockProducts(this.selectedProductsIdList)
          .then(r => {
            this.selectedProductsIdList = []
            this.selectedCategoriesIdList = []
            this.categoryProductsFetchStatus = {}
            this.actionClearStockProducts()
            this.actionFetchStockCategories(true)

            this.reply_dialog.text = r.detail
            this.reply_dialog.errors = r.errors || []
            this.reply_dialog.show = true
            this.reply_dialog.exported_goods = r.exported_goods
          })
          .finally(() => {
            this.exportingInProgress = false
          })
    }
  }
}
</script>

<style scoped lang="scss">
.content-left,
.content-right {
  max-width: 100%;
}
</style>
