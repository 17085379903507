var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.productsList.length)?_c('v-expansion-panel',{attrs:{"disabled":_vm.loading}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":`${_vm.category.name} (${_vm.countSelectedProducts}/${_vm.countProducts})`,"indeterminate":_vm.isIndeterminate,"hide-details":""},on:{"change":_vm.changeCategory},model:{value:(_vm.categorySelected),callback:function ($$v) {_vm.categorySelected=$$v},expression:"categorySelected"}})],1),_c('v-overlay',{attrs:{"color":"white","value":_vm.loading,"absolute":""}},[_c('v-progress-circular',{attrs:{"size":36,"color":"indigo lighten-4","indeterminate":""}})],1)]},proxy:true}],null,false,963820531)}),_c('v-expansion-panel-content',{staticClass:"pa-0 export_goods"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","item-key":"id","show-select":"","headers":[
        { text: 'Артикул', value: 'article'},
        { text: 'Имя', value: 'name' },
        { text: 'Закупочная цена', value: 'price1' },
        { text: 'Ед. изм.', value: 'unit'},
        { text: 'Кол-во в упаковке', value: 'pieces_in_package' }
      ],"items":_vm.productsList},on:{"item-selected":({item, value}) => _vm.changeProduct(item.id, value)},scopedSlots:_vm._u([{key:"item.unit",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$store.state.stock_units.stock_units.filter(u => u.id == item.unit)[0].name)+" ")]}},{key:"header.data-table-select",fn:function(){return undefined},proxy:true}],null,false,152415690),model:{value:(_vm.selectedProductsIdListComputed),callback:function ($$v) {_vm.selectedProductsIdListComputed=$$v},expression:"selectedProductsIdListComputed"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }