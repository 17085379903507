<template>
  <v-expansion-panel :disabled="loading" v-if="productsList.length">
    <v-expansion-panel-header>
      <template #default>
        <div @click.stop>
          <v-checkbox
            v-model="categorySelected"
            @change="changeCategory"
            :label="`${category.name} (${countSelectedProducts}/${countProducts})`"
            :indeterminate="isIndeterminate"
            class="mt-0"
            hide-details
          ></v-checkbox>
        </div>
        <v-overlay color="white" :value="loading" absolute>
          <v-progress-circular
            :size="36"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0 export_goods">
      <v-data-table
        v-model="selectedProductsIdListComputed"
        dense
        item-key="id"
        show-select
        class="elevation-0"
        :headers="[
          { text: 'Артикул', value: 'article'},
          { text: 'Имя', value: 'name' },
          { text: 'Закупочная цена', value: 'price1' },
          { text: 'Ед. изм.', value: 'unit'},
          { text: 'Кол-во в упаковке', value: 'pieces_in_package' }
        ]"
        :items="productsList"
        @item-selected="({item, value}) => changeProduct(item.id, value)"
      >
        <template #item.unit="{ item }">
          {{ $store.state.stock_units.stock_units.filter(u => u.id == item.unit)[0].name }}
        </template>
        <template #header.data-table-select></template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    category: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedProductsIdList: [],
    categorySelected: false,
  }),
  watch: {
    selectedProductsIdList: function(newValue, oldValue) {
      this.$emit('product-change', {
        newValue: [...newValue],
        oldValue: [...oldValue]
      })
    }
  },
  computed: {
    ...mapGetters('stock_products', ['getStockProductsByParams']),
    selectedProductsIdListComputed: {
      get() {
        return this.productsList.filter(p => this.selectedProductsIdList.indexOf(p.id) !== -1)
      },
      set(products) {
        // window.a = arguments
        //
        //
        // this.$nextTick(() => {
        //           for (let product of products) {
        //   if (product && this.selectedProductsIdList.indexOf(product) == -1)
        //     this.selectedProductsIdList.push(product.id)
        // }
        //
        // })
      }
    },
    productsList: function() {
      return this.getStockProductsByParams({
        structure: this.category.id
      })
    },
    productIdList: function() {
      return this.productsList.map(({ id }) => id)
    },
    countSelectedProducts: function() {
      return _.intersection(this.productIdList, this.selectedProductsIdList)
        .length
    },
    countProducts: function() {
      return this.productsList.length
    },
    isIndeterminate: function() {
      const diff = _.difference(this.productIdList, this.selectedProductsIdList)
      return diff.length > 0 && diff.length !== this.productsList.length
    }
  },
  methods: {
    changeCategory: function(value) {
      this.selectedProductsIdList = value ? [...this.productIdList] : []
    },
    changeProduct: function(productId, value) {
      if (value) {
        this.selectedProductsIdList = [...this.selectedProductsIdList, productId]
      } else {
        this.selectedProductsIdList = this.selectedProductsIdList.filter(id => productId !== id)
      }

      this.categorySelected = this.selectedProductsIdList.length > 0
    }
  }
}
</script>

<style>
.export_goods .v-simple-checkbox i {
  color: #1976D2;
}
</style>